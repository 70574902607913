import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {DynamicScriptLoaderService} from "./script-loader.service";

@Component({
  selector: 'stocktips-index',
  styleUrls: ['./stocktips-home.component.css'],
  templateUrl: './stocktips-home.component.html',
})
export class StocktipsHomeComponent implements OnInit {

  showHomePage: boolean = true
  showTermsAndConditionsPage: boolean = false;
  showPrivacy: boolean = false;
  showCancellations: boolean = false;
  showShipping: boolean = false;

  constructor(private router: Router, private dynamicScriptLoader: DynamicScriptLoaderService) {
  }

  ngOnInit() {
    this.loadScripts();
    if (document.location.pathname.indexOf("terms") >= 0 || document.location.pathname.indexOf("privacy") >= 0 ||
      document.location.pathname.indexOf("cancel") >= 0 || document.location.pathname.indexOf("shipping") >= 0) {
      this.showHomePage = false;
    } else {
      this.showHomePage = true;
    }
  }

  private loadScripts() {
    this.dynamicScriptLoader.load('sa-script').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }

  redirectToYoutube() {
    window.open("https://www.youtube.com/playlist?list=PLzX1C0OguvAdBf0A3uRevC73hyBpAaydi");
  }
}
