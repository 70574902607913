import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {StocktipsHomeComponent} from "./pages/stocktips/stocktips-home.component";

const routes: Routes = [
  {
    path: "app/home",
    component: StocktipsHomeComponent,
  },
  {
    path: "app/terms-and-conditions",
    component: StocktipsHomeComponent,
  },
  {
    path: "app/privacy-policy",
    component: StocktipsHomeComponent,
  },
  {
    path: "app/cancellation-policy",
    component: StocktipsHomeComponent,
  },
  {
    path: "app/shipping-policy",
    component: StocktipsHomeComponent,
  },
  {path: '', redirectTo: 'app/home', pathMatch: 'full'},
  {path: '**', redirectTo: 'app/home'},
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
