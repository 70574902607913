import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";
import {StocktipsHomeComponent} from "./pages/stocktips/stocktips-home.component";
import {DynamicScriptLoaderService} from "./pages/stocktips/script-loader.service";

@NgModule({
  declarations: [AppComponent, StocktipsHomeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
  ],
  bootstrap: [AppComponent],
  providers: [DynamicScriptLoaderService, NgxSpinnerService]
})
export class AppModule {
}
